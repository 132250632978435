import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

import { routes } from './routes'

Vue.config.productionTip = false

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {
        selector: to.hash,
      }
    }

    return { x: 0, y: 0 }
  },
  routes,
  mode: 'history',
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
