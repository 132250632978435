import Index from './components/Index'
import Airdrop from './components/Airdrop'
import Stake from './components/Stake'

export const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/airdrop',
    name: 'airdrop',
    component: Airdrop,
  },
  {
    path: '/stake',
    name: 'stake',
    component: Stake,
  },
]
